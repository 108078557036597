.wrapper {
  .name {
    padding-top: 10px;
    padding-bottom: 5px;
    text-align: center;
    font-size: 3.5em;
  }

  .stats {
    display: flex;
    justify-content: space-around;
  }
}